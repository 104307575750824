/* @tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

.shake:hover {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: translate(3px, 0);
  }
  50% {
    -moz-transform: translate(-3px, 0);
  }
  100% {
    -moz-transform: translate(0, 0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
  }
  50% {
    -webkit-transform: translate(-3px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform: translate(3px, 0);
  }
  50% {
    -ms-transform: translate(-3px, 0);
  }
  100% {
    -ms-transform: translate(0, 0);
  }
}

@-o-keyframes shake {
  0% {
    -o-transform: translate(3px, 0);
  }
  50% {
    -o-transform: translate(-3px, 0);
  }
  100% {
    -o-transform: translate(0, 0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif; /* Use Rubik font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.country .ant-select-selection-item,.country .ant-select-selection-placeholder{ 
  padding-left: 1.5rem !important;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
  padding-left: 5px !important; 

}
.ant-select-selection-placeholder{
  font-size:'8px' !important

}
#clientname{
  color: rgb(0 0 0 / 96%);
  border: 2px solid #00A7B5;
}
#Country ,#Financialyear {
  border: none;
}
#Country:focus ,#Financialyear:focus {
  outline: none  !important;
}
.clientname:focus{
  border: 2px solid #00A7B5;
}
.clientname-error{
  border-color: #FF6174 !important;
}

.custom-data-table > td, th {
  border: none !important;
  text-align: left;
  /* padding: 0.5rem 0 !important; */
}

/* tbody>tr:nth-child(even) {
  background-color: #ECECEC !important;
}

tbody>tr:nth-child(odd) {
  background-color: #E1E1E1 !important;
} */

.elipsesTooltip{
  display: -webkit-box;
  max-height: 2.9rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-tooltip-inner {
  color: inherit !important;
  background-color: white !important;
}

.ant-select-selector {
 border: none !important;
 box-shadow: none !important;
 padding-left: 0.5rem !important;
}
.ant-picker-cell {
  border: 1px solid white;
  height: 5px !important;
  width: 5px !important;  
  padding: 2px !important; 
}

@media (min-width: 1024px) and (max-width:1199px){

.ant-picker-date-panel{
  width: 205px !important;
}
.ant-picker-cell-inner{
  height: 15px !important;
  width: 16px !important;
  min-width: 10px !important;
  font-size: 10px !important;
  line-height: 15px !important;
}
.ant-picker-cell{
  min-width: 12px;  
  height: 12px !important;
  width: 12px !important;
}
.ant-picker-cell-in-view{
  min-width: 12px;  
  height: 12px !important;
  width: 12px !important;
}
.ant-picker-content{
  width: 200px !important;
  font-size: 12px !important;
}
.ant-picker-header{
  padding:0 2px !important
}
.ant-picker-header-view{
  font-size: 12px !important;
}
.ant-picker-body{
  padding: 0px 2px 2px 2px !important;
}
.ant-picker .ant-picker-input >input{
  font-size: 12px !important;
}
.ant-picker-dropdown .ant-picker-content th{
  height: 15px !important;
  width: 14px !important;
}
.ant-select-single .ant-select-selector{
  font-size: 12px;
}
.ant-select-item-option-content{
  font-size: 10px !important;
  min-height: 0 !important;
}

}

@media (min-width: 1200px){
  .ant-picker-date-panel{
    width: 269px !important;
  }  
  .ant-picker-body{
    padding: 0px 8px 8px 8px !important;
    }
    .ant-select-single .ant-select-selector{
      font-size: 12px;
    }
    .ant-select-item-option-content{
      font-size: 12px !important;
      min-height: 0 !important;
    }
}

/* Your custom CSS file or style block */
.ant-picker-content th {
  padding: 0 !important;
  text-align: center !important;
}

.custom-date-picker .ant-picker-header .ant-picker-year-btn{
  display: none !important;
}

.custom-date-picker .ant-picker-header .ant-picker-header-super-next-btn,
.custom-date-picker .ant-picker-header .ant-picker-header-super-prev-btn {
  display: none !important;
}

.thin-scrollbar::-webkit-scrollbar-track{
  background: #f1f1f1 !important;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #999 !important; 
  border-radius: 10px !important;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 5px !important; 
  height: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  right: 0;
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 5px;
  height: 5px;
}

.custom-slide-pane-overflow >div {  
  overflow-y: hidden !important; 
}
.slide-pane__content {
  position: relative;
  overflow-y: auto !important;
  padding: 0 !important;
  flex: 1 1 auto;
  /* border:none !important; */
}

.custom-overlay-class{
height: calc(100% - 6rem) !important;
margin-top: 4rem;
}

.color-change-image {
  filter: grayscale(100%) sepia(100%) hue-rotate(0deg) saturate(0%);
}
/* .custom-reduce-hight{
  height: calc(100% - 4rem) !important;
} */

/* For lg screens */
@media (min-width: 1024px) and (max-width:1199px){
  .custom-hight-calculation{
    height: calc(100% - 3.8rem) !important;
  }
  .custom-overlay-class{
    height: calc(100% - 4.4rem) !important;
    margin-top: 2.5rem !important;
    }
    .model-builder-height-reduce{
      height: calc(100% - 4.2rem) !important;    
    }   
    .custom-hight-calculationView{
      height: calc(100% - 0.1rem) !important;
    }
}
.custom-hight-calculationView{
  height: calc(100% - 1.5rem) !important;
}
@media (min-width: 1200px) and (max-width:1279px){
  .model-builder-height-reduce{
    height: calc(100% - 4.1rem) !important; 
  }
}
@media (min-width: 1280px){
  .model-builder-height-reduce{
    height: calc(100% - 6.5rem) !important;
  
  }
  .custom-hight-calculation{
    height: calc(100% - 5rem);
  }  
   
}
@media only screen and (min-width: 1280px) and (min-height: 559px) {
  .mini-xl-screen{
  bottom: 0 !important;
  }  
}

@media (min-width: 1200px) and (max-width:1279px){
  .custom-hight-calculation{
    height: calc(100% - 4rem) !important;
  }
  .custom-overlay-class{
    height: calc(100% - 4.6rem) !important;
    margin-top: 2.5rem !important;
    }     
}

/* For lg screens */
@media (max-width: 1279px) {
  .custom-select-height {
    height: 1.7rem;
  }  
}

/* For xl screens */
@media (min-width: 1280px) {
  .custom-select-height {
    height: 2.2rem;
  }
  .ant-select-single .ant-select-selector{
    font-size: 14px;
  }
}
@media (min-width: 1600px) {
.custom-overlay-class{
  height: calc(100% - 6.7rem) !important;
  margin-top: 4rem;
  }
}

.page-hight-reduce{
  height: calc(100% - 8rem) !important;
}
.slide-pane__overlay.overlay-after-open{
  background-color:transparent !important;
  pointer-events: auto !important; 

}
#rightPanel {
  transition: transform 0.3s ease-in-out;
}
 /* For WebKit (Chrome, Safari) */
 progress::-webkit-progress-bar {
  background-color: #E7E6E6; /* Light gray color */
  height: 1rem; /* Adjust height as needed */
  /* width: 30rem; */
}

progress::-webkit-progress-value {
  background-color: #00A7B5; /* Highlighting color */
}

/* For Firefox */
progress::-moz-progress-bar {
  background-color: #E7E6E6; /* Light gray color */
  height: 1rem; /* Adjust height as needed */
}
@media (max-width: 1447px) {  
  .custom-table-height {
    height: 9.5rem;
  }
}
/* Add this to your CSS */
.custom-disabled-datepicker.ant-picker-disabled:hover {
  background-color: white !important; /* Set your desired hover background color */
}

/* Add this to your CSS */
.custom-disabled-select.ant-select-disabled:hover {
  background-color: white !important; /* Set your desired hover background color */
}

.custom-accordin-height-reduce{
  height: calc(100% - 10rem) !important;
}

.material-react-table-header-icon {
  color: white !important;
}
/* .MuiBadge-root, .MuiIconButton-root{
  filter: brightness(0) invert(1) !important;
} */
.Mui-TableHeadCell-Content-Actions > .MuiButtonBase-root,.MuiTableSortLabel-icon{
  filter: brightness(0) invert(1) !important;

}
.MuiFormControlLabel-label,.MuiButton-text{
font-size: 14px !important;
}
.MuiSwitch-sizeMedium{
  width: 57px !important;
  height: 38px !important;
}

.MuiTable-root .MuiTableCell-head[data-pinned="true"]:before {
  background-color: inherit !important;
  box-shadow: -4px 0 4px -4px rgba(97, 97, 97, 0.5) inset;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.custom-table {
  border-collapse: collapse;  
  font-size: 12px;
  background-color: white !important; 
}

.custom-td {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 7px !important;
  padding-left: 30px !important;


}
.custom-tr{
  border-bottom: 1px solid #E7E6E6!important;
}

.custom-tr:hover {
  background-color: rgb(231, 230, 230) !important;
}
.custom-th {
  background-color: #385723 !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  color: white;
}

.custom-radio,input[type=checkbox]{
  accent-color: Teal;

}
.custom-checkbox,input[type=checkbox]{
  accent-color: #2F5597;

}
.MuiBox-root.css-1crctjg button:first-child {
  display: none;
}
#CalculationDate::placeholder {
  color: #242B3B !important; 
  font-size: '10px' !important;
}

.table-pagination .MuiPaginationItem-root {
  color: #707070 !important;
  /* font-family: 'Rubik'; */
  font-size: 13px;
  font-weight: 600;
  min-width: 20px !important;
    height: 20px;
}

.table-pagination .Mui-selected {
  background-color: #D0CECE !important;
  color: white !important;
}

.table-pagination .MuiPaginationItem-previousNext {
  border: 1px solid #D0CECE;
}

.table-pagination .MuiPaginationItem-icon {
  font-size: 2rem;
}

.analysisCheckGroup .ant-checkbox-group-item{
  width: 100%;
  font-size: 13px;
}

@media (min-width: 768px) {
  .table-pagination .MuiPaginationItem-icon {
    font-size: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .table-pagination .MuiPaginationItem-root {
    font-size: 16px;
    font-weight: 600;
    min-width: 26px !important;
    height: 26px;
  }
}
.analysisCheckGroup .ant-checkbox-group-item,
.analysisCheckGroup .ant-checkbox-group-item span:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterRangeSelect .ant-select-selection-item {
  font-size: 12px;
}

.place::placeholder{
  color: #BEBEBE;
  font-size: 13px;
}







.table-container {
  max-height: 220px; 
  overflow-y: auto;
}


table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  position: sticky;
  top: 0;
  background-color: #fff; 
  z-index: 1; 
}

.custom-modal .anticon svg{
  display: none !important;

}
.custom-modal .ant-modal-confirm-btns{
  display: flex;
  justify-content: center;
}

.custom-modal .ant-btn-primary{

    color: rgb(255, 255, 255);
    background-color: rgb(0, 167, 181);
}

.custom-modal .ant-btn-default {
  color: rgb(32, 33, 53);
  background-color: rgb(255, 255, 255);
  border-color: rgb(47, 85, 151);
}

.custom-modal .ant-modal-confirm-title{
background-color: #4F2D7F !important;
color: white !important;
text-align: center !important;
}

.custom-modal .ant-modal-content{
padding: 0 !important;
}

.custom-modal .ant-modal-confirm-paragraph{
  width: 100% !important;
  max-width: 100% !important;
}