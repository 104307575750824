
.card-skeleton-icons{
    width: 1rem !important;
    height: 1rem !important;
}
.card-skeleton-line{
    height: 0.2rem !important;
    margin: 0 !important;

}
.card-skeleton-text{
    height: 0.4rem !important;
    width:5rem !important;
    margin: 0 !important;

}
.card-skeleton-textlong{
    height: 0.4rem !important;
    width:10rem !important;
    margin: 0 !important;
}
.card-skeleton-title{
    height: 1rem !important;
    width: 12rem !important;
    border-radius: 10px !important;
}
.card-skeleton-header{
    height: 1.5rem !important;
    width: 21rem !important;
    margin: 0 !important;
}
.skeletonColor{
    background-color: white !important;
}